<template>
  <div>
    <div
      class="modal fade"
      id="modal-cambio-ventana"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambiar ventana</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body">
              <div class="row">
                <div class="row" v-if="accionVentana == 0">
                  <div class="col-md-6">
                    <button
                      v-if="ventanaEnturnamientoCargue !== null"
                      type="button"
                      class="btn btn-primary"
                      v-show="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.cambiarVentana'
                        )
                      "
                      @click="selectVentana(1)"
                    >
                      Ventana cargue
                    </button>
                  </div>
                  <div class="col-md-6">
                    <button
                      v-if="ventanaEnturnamientoDescargue !== null"
                      type="button"
                      class="btn btn-primary"
                      v-show="
                        $store.getters.can(
                          'hidrocarburos.ventanasOperaciones.cambiarVentana'
                        )
                      "
                      @click="selectVentana(2)"
                    >
                      Ventana descargue
                    </button>
                  </div>
                  <div v-if="ventana == 0">
                    <p>
                      <span class="badge badge-danger">
                        No hay ventanas para mostrar
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row" v-else>
                  <div v-if="accionVentana == 2">
                    <div class="form-group col-md-12">
                      <label>Ventana enturnamiento actual</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ventanaEnturnamientoDescargue"
                        placeholder="Ventanas Enturnamiento"
                        label="nombre"
                        disabled="disabled"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Ventana enturnamiento (cambio)</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ventanaEnturnamientoNueva"
                        placeholder="Ventanas Enturnamiento"
                        label="nombre"
                        :options="listasForms.ventanasEnturneDescargue"
                        :filterable="false"
                      ></v-select>
                    </div>
                  </div>
                  <div v-if="accionVentana == 1">
                    <div class="form-group col-md-12">
                      <label>Ventana enturnamiento actual</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ventanaEnturnamientoCargue"
                        placeholder="Ventanas Enturnamiento"
                        label="nombre"
                        disabled="disabled"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Ventana enturnamiento (cambio)</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ventanaEnturnamientoNueva"
                        placeholder="Ventanas Enturnamiento"
                        label="nombre"
                        :options="listasForms.ventanasEnturneCargue"
                        :filterable="true"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="accionVentana !== 0"
          >
            <div v-if="accionVentana == 2">
              <button
                type="button"
                class="btn btn-primary"
                v-show="
                  !$v.ventanaEnturnamientoNueva.$invalid &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.cambiarVentana'
                    )
                "
                @click="cambiarVentanaDescargue()"
              >
                Cambiar
              </button>
            </div>
            <div v-if="accionVentana == 1">
              <button
                type="button"
                class="btn btn-primary"
                v-show="
                  !$v.ventanaEnturnamientoNueva.$invalid &&
                    $store.getters.can(
                      'hidrocarburos.ventanasOperaciones.cambiarVentana'
                    )
                "
                @click="cambiarVentanaCargue()"
              >
                Cambiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "VentanaOperacionesCambioVentana",
  components: {
    vSelect,
  },
  data() {
    return {
      ventanaEnturnamientoCargue: null,
      ventanaEnturnamientoDescargue: null,
      accionVentana: 0,
      ventana: 0,
      ventanaEnturnamientoNueva: [],
      viaje_id: null,
      ventana_id: null,
      sitio_id: null,
      sitio_type: null,
      form: {
        viaje_id: null,
        ventana_enturnamiento_id: null,
      },
      listasForms: {
        ventanasEnturneCargue: [],
        ventanasEnturneDescargue: [],
        ventanasEnturne2: [],
      },
    };
  },
  validations: {
    ventanaEnturnamientoNueva: {
      required,
    },
  },
  methods: {
    buscarVentanasEnturneCargue(sitio_id) {
      let me = this;
      axios
        .get("api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanas", {
          params: {
            sitio_id: sitio_id,
            sitio_type: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasEnturneCargue = respuesta;
          let itemELiminar = 0;
          let i = 0;
          me.listasForms.ventanasEnturneCargue.forEach((element) => {
            if (element.id == me.ventanaEnturnamientoCargue.id) {
              itemELiminar = i;
            }
            i++;
          });
          me.listasForms.ventanasEnturne2 = me.listasForms.ventanasEnturneCargue.splice(
            itemELiminar,
            1
          );
        })

        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarVentanasEnturneDescargue(sitio_id) {
      let me = this;
      axios
        .get("api/hidrocarburos/ventanasEnturnamientoOperacion/listaVentanas", {
          params: {
            sitio_id: sitio_id,
            sitio_type: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasEnturneDescargue = respuesta;
          let itemELiminar = 0;
          let i = 0;
          me.listasForms.ventanasEnturneDescargue.forEach((element) => {
            if (element.id == me.ventanaEnturnamientoDescargue.id) {
              itemELiminar = i;
            }
            i++;
          });
          me.listasForms.ventanasEnturne2 = me.listasForms.ventanasEnturneDescargue.splice(
            itemELiminar,
            1
          );
        })

        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    llenar_modal_cambio_ventana(viaje) {
      this.accionVentana = 0;
      this.ventana = 0;
      this.form.id = viaje.id;
      this.ventanaEnturnamientoCargue = null;
      this.ventanaEnturnamientoDescargue = null;
      if (viaje.ventana_cargue != null) {
        this.ventanaEnturnamientoCargue = viaje.ventana_cargue;
        this.buscarVentanasEnturneCargue(viaje.sitio_cargue_id);
        this.ventana++;
      }
      if (viaje.ventana_descargue != null) {
        this.ventanaEnturnamientoDescargue = viaje.ventana_descargue;
        this.buscarVentanasEnturneDescargue(viaje.sitio_descargue_id);
        this.ventana++;
      }
      this.ventanaEnturnamientoNueva = [];
    },

    selectVentana(ventana) {
      this.accionVentana = ventana;
    },

    cambiarVentanaCargue() {
      if (!this.$v.ventanaEnturnamientoNueva.$invalid) {
        this.form.ventana_enturnamiento_id = this.ventanaEnturnamientoNueva.id;
        axios({
          method: "PUT",
          url: "api/hidrocarburos/viajes/cambiarVentanaCargue",
          data: this.form,
        }).then(() => {
          this.$parent.getIndex();
          this.$refs.closeModal1.click();
          this.form = {};
          this.ventanaEnturnamientoNueva = [];

          this.$swal({
            icon: "success",
            title: "Se actualizó la ventana de cargue exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },

    cambiarVentanaDescargue() {
      if (!this.$v.ventanaEnturnamientoNueva.$invalid) {
        this.form.ventana_enturnamiento_id = this.ventanaEnturnamientoNueva.id;
        axios({
          method: "PUT",
          url: "api/hidrocarburos/viajes/cambiarVentanaDescargue",
          data: this.form,
        }).then(() => {
          this.$parent.getIndex();
          this.$refs.closeModal1.click();
          this.form = {};
          this.ventanaEnturnamientoNueva = [];

          this.$swal({
            icon: "success",
            title: "Se actualizó la ventana de descargue exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      }
    },
  },
};
</script>
